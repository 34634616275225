.rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.rate:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 30px;
}
.rate:last-child {
  margin-bottom: 0;
}
.rate .bedAndFees {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575.98px) {
.rate .bedAndFees {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.rate .bedAndFees .feesBox {
    min-width: 100%;
    margin-top: 0.5rem;
}
}
.rate .bedAndFees > div {
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
  min-width: 50%;
}
.rate .bedAndFees .form-group {
  margin-bottom: 0;
}
.rate .rateTop {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 100%;
}
.rate .rateTop .boxsetResume {
  margin-top: -0.5rem;
}
.rate .rateTop hr {
  display: block;
  margin: 2rem 0;
}
.rate .rate__left {
  min-width: 100%;
}
.rate .rate__left .custom-control-label {
  color: #333333;
  font-size: 14px;
  padding-top: 0;
}
.rate .rate__right {
  min-width: 100%;
}
.rate .selectBoxsetButton,
.rate .feesBox {
  max-width: 200px;
  margin: auto;
}
.rate .amenity,
.rate .property__prices-occupancy {
  min-width: 50%;
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
}
.rate .property__prices-occupancy {
  color: rgba(102, 102, 102, 0.5);
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
.rate .rate__title {
  color: #333333;
  font-size: 22px;
  font-weight: 600;
}
@media (min-width: 992px) {
.rate .fail {
    white-space: nowrap;
}
}
.rate .property__amenities .amenity {
  width: 50%;
}
.rate .fees {
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  line-height: 120%;
  margin: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.rate .fees .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rate .fees svg {
  min-width: 12px;
  width: 12px;
  margin-top: -1px;
  margin-right: 6px;
}
.rate .fees span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rate .boxsetPadProperty {
  padding: 0.75rem 0.95rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #fff;
}