#property-prices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  /*
  .property__prices-occupancy
  {
      color:rgba($smooth-black,0.5);
      font-size:12px;
      display:flex;
      flex-direction:column;
  }
  */
}
#property-prices .property__resume {
  width: 70%;
}
#property-prices .creditKoCopy {
  display: block;
  font-size: 11px;
  margin-top: 1rem;
  margin-bottom: 0;
}
#property-prices .creditKoCopy svg {
  width: 14px;
}
#property-prices .boxsetPurchase {
  width: 100%;
  text-align: center;
}
#property-prices .btn-with-icon {
  display: block;
  width: 100%;
  max-width: 160px;
  margin: auto;
  margin-top: 1rem;
}
#property-prices .btn-with-icon svg path {
  stroke: #fff;
}
#property-prices .resumeSmall {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
#property-prices .resumeSmall * {
  font-size: 13px;
  margin-right: 6px;
}
#property-prices .resumeSmall .salaBadge {
  padding: 0.15rem 0.75rem;
}
#property-prices .resumeSmall svg {
  width: 20px;
  height: 20px;
}
#property-prices .resumeSmall .boxsetResume {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#property-prices hr {
  display: none;
}
#property-prices button {
  width: 100%;
}
#property-prices .selectBoxsetButton {
  text-transform: capitalize;
}
#property-prices .selectBoxsetButton svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-left: 0;
}
#property-prices .selectBoxsetButton svg * {
  fill: #fff;
}
#property-prices .selectBoxsetInfo {
  margin-top: 0.75rem;
  margin-bottom: 0;
}
#property-prices .selectBoxsetInfo strong {
  display: block;
  margin-bottom: 0.35rem;
}
@media (min-width: 768px) {
#property-prices {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#property-prices button {
    width: auto;
}
#property-prices hr {
    display: block;
}
#property-prices .property__resume {
    width: 100%;
}
}
#property-prices .property__resume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#property-prices .property__prices-total {
  color: #0077CA;
  font-size: 22px;
  font-weight: 600;
}
@media (min-width: 1200px) {
#property-prices .property__prices-total {
    font-size: 30px;
}
}
#property-prices .property__prices-per-night {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
}
#property-prices .property__prices-alert {
  color: #dc3545;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}
#property-prices .property__prices-alert svg {
  width: 14px;
  margin-left: 3px;
}
#property-prices .property__prices-alert svg * {
  fill: #dc3545;
}
#property-prices hr {
  border-top: 1px solid #dee2e6;
  width: 80%;
  margin: 0.75rem auto;
}
#property-prices button {
  font-size: 15px;
  margin-top: 1rem;
  font-weight: 600;
  padding: 10px;
  font-weight: 400;
  width: 100%;
}
#property-prices button svg {
  margin-left: 8px;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
#property-prices .danger {
  color: #dc3545;
}
.gold .rate__right .property__prices-total,
.gold .rate__right .property__prices-alert {
  color: #FF9F00 !important;
}
.gold .rate__right .property__prices-alert svg * {
  fill: #FF9F00 !important;
}
.red .rate__right .property__prices-total,
.red .rate__right .property__prices-alert {
  color: #dc3545 !important;
}
.red .rate__right .property__prices-alert svg * {
  fill: #dc3545 !important;
}
.default .rate__right .property__prices-total,
.default .rate__right .property__prices-alert {
  color: #0077CA !important;
}
.default .rate__right .property__prices-alert svg * {
  fill: #0077CA !important;
}
.modal-prices-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}