.room .room__bedding-alert {
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
}
.room .room__bedding-alert .bedding-alert {
  font-size: 14px;
}
.room .room__bedding-alert svg {
  width: 16px;
  opacity: 0.75;
  margin-top: -3px;
  margin-right: 4px;
}
.room .room__name {
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
.room .room__name {
    font-size: 25px;
}
}
.room .room__occupancy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 15px;
  margin-bottom: 2rem;
}
.room .room__occupancy svg {
  margin-right: 8px;
}
.room .room__modal-link {
  margin-top: 15px;
}
.room .room__image {
  width: 100%;
  min-height: 260px;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 768px) {
.room .room__image {
    min-height: 340px;
}
}
@media (max-width: 1199.98px) {
.room .room__right .rate {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.room .room__right .rate button {
    margin-bottom: 1rem;
}
.room .room__right .rate .custom-control-label {
    font-size: 13px;
}
.room .room__right .rate hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.room .room__right .rate .rate__left,
.room .room__right .rate .rate__right {
    width: 100%;
}
.room .room__right .rate .rate__right {
    margin-top: 1rem;
    padding-left: 0;
}
}
@media (max-width: 991.98px) {
.room .room__bedding-alert {
    padding: 0 20px;
    margin: 20px 0 0 0;
}
.room .room__occupancy {
    padding-top: 0;
}
.room .room__occupancy br {
    display: none;
}
.room .room__name {
    font-size: 18px;
}
.room .room__modal-link {
    margin-top: 6px;
}
.room .room__modal-link .btn-chevron-right {
    font-size: 13px;
}
.room .room__modal-link .btn-chevron-right svg {
    width: 6 px;
}
.room .room__name,
.room .room__occupancy {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}
}
.room .room__left-inner,
.room .room__right-inner {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767.98px) {
.room .room__left-inner .panel-white,
.room .room__right-inner .panel-white {
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent;
    padding: 0;
}
}
@media (min-width: 992px) {
.room .room__left {
    padding-right: 0;
    border-right: 1px solid #dee2e6;
}
.room .room__left-inner {
    margin-right: 90px;
}
}
.room .roomDivider {
  display: none;
}
@media (min-width: 992px) {
.room .roomDivider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}
.room .roomDivider:after {
    content: "";
    height: 100%;
    right: 0;
    width: 1px;
    background: #dee2e6;
    position: absolute;
}
}
.room .boxsetPadProperty {
  font-weight: bold;
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  color: #fff;
  position: relative;
  padding-left: 2.5rem;
}
.room .boxsetPadProperty svg {
  width: 26px;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.room .boxsetPadProperty svg * {
  fill: #fff;
}
@media (max-width: 991.98px) {
.room .boxsetPadProperty {
    text-align: left;
    font-size: 14px;
}
}
.room .boxsetPadProperty svg {
  position: absolute;
  top: 0px;
  left: 10px;
  width: 20px;
}
@media (max-width: 575.98px) {
.room.panel-gray {
    padding: 20px 20px;
}
.room .room__name,
.room .room__occupancy {
    padding-left: 0;
    padding-right: 0;
}
}