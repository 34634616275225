@media (max-width: 575.98px) {
#property-details .reviewsTitle {
    text-align: center;
}
}
#property-details .hide-multiple-spinner + .hide-multiple-spinner {
  display: none !important;
}
@media (max-width: 991.98px) {
#property-details .map-link {
    margin-top: 20px;
}
}
#property-details .map-link svg {
  width: 100%;
  height: auto;
}
#property-details .topBtn {
  margin-bottom: 20px;
}
#property-details .propertyHeading {
  margin-top: 0.25rem;
  margin-bottom: 1.85rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 992px) {
#property-details .propertyHeading .property__address {
    display: none;
}
}
#property-details .topProperty {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#property-details .topProperty .property__scoredesc {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 12px;
}
#property-details .property__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
}
#property-details .property__top > div {
  width: 100%;
}
#property-details .property__top .d-flex {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 992px) {
#property-details .property__top {
    margin-top: 0;
}
#property-details .property__top .d-flex {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    width: 100%;
}
}
#property-details .property__top .property__rates {
  margin-top: 4px;
}
#property-details .property__top .property__address {
  margin-top: 4px;
  margin-bottom: 0;
  color: #adb5bd;
}
#property-details .property__top .property__address svg {
  margin-right: 4px;
}
#property-details .property__top .property__address svg * {
  fill: #adb5bd;
}
#property-details .boxsetPadPropertyColumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#property-details .boxsetPadPropertyColumn .infoCircle {
  width: 16px;
  margin-bottom: 4px;
}
#property-details .boxsetPadPropertyColumn .infoCircle path {
  fill: #fff;
}
#property-details .boxsetPadPropertyColumn .boxsetPadProperty {
  position: relative;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
@media (min-width: 992px) {
#property-details .boxsetPadPropertyColumn .boxsetPadProperty {
    padding: 1rem 1.25rem;
    text-align: left;
    margin-top: 0;
}
}
#property-details .boxsetPadPropertyColumn .triangleRight {
  display: none;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid green;
}
@media (min-width: 1200px) {
#property-details .boxsetPadPropertyColumn {
    padding-right: 0;
}
#property-details .boxsetPadPropertyColumn .triangleRight {
    display: block;
}
}
#property-details .property__heading {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 1rem;
  width: 100%;
}
@media (min-width: 1200px) {
#property-details .property__heading {
    width: auto;
    font-size: 25px;
}
}
#property-details .property__highlight {
  margin-bottom: 15px;
  overflow: hidden;
}
#property-details .property__highlight .btn-chevron-right {
  margin-top: 0.5rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#property-details .property__highlight .highlight__right {
  padding: 30px;
}
#property-details .property__highlight .highlight__right hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#property-details .property__highlight .highlight__right .proximity__box {
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 11px;
  margin-top: 3px;
}
#property-details .property__highlight .highlight__right .proximity__box svg {
  height: auto;
  width: 9px;
  margin-right: 6px;
  margin-left: 2px;
}
#property-details .property__highlight .highlight__right .proximity__box svg path {
  fill: #333333;
}
@media (max-width: 575.98px) {
#property-details .property__highlight .highlight__right {
    padding-left: 0;
    padding-right: 0;
}
}
@media (min-width: 992px) {
#property-details .property__highlight {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
#property-details .property__highlight .highlight__left {
    width: 50%;
}
#property-details .property__highlight .highlight__right {
    padding: 0 0 30px 30px;
    width: 50%;
}
#property-details .property__highlight .highlight__right hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
}
#property-details .map-detail {
  cursor: pointer;
}
#property-details .map-detail svg {
  display: block;
}
#property-details .property__amenities {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
#property-details .property__amenities .amenity {
  font-weight: 500;
  width: auto;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
  line-height: normal;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
#property-details .property__amenities .amenity:before {
  margin-top: 3px;
}
#property-details .property-image {
  width: 100%;
  min-height: 220px;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 768px) {
#property-details .property-image {
    min-height: 320px;
}
}
@media (min-width: 992px) {
#property-details .property-image {
    min-height: 440px;
}
}
#property-details .property__address {
  margin-top: 1rem;
  color: #0077CA;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#property-details .property__address svg {
  margin-right: 8px;
}
@media (min-width: 768px) {
#property-details .property__address {
    font-size: 15px;
}
}
#property-details .property__description {
  font-size: 15px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 0;
}
#property-details .property__description i {
  cursor: pointer;
  color: #0077CA;
  font-weight: 500;
}
#property-details .detail-first-title {
  margin-top: 1rem;
  color: #0077CA;
}
#property-details .rooms__title {
  display: block;
  padding: 0;
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
#property-details .rooms__title {
    font-size: 25px;
}
}
#property-details .info__panel {
  padding: 30px;
  border-radius: 6px;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  background: #fff;
}
#property-details .info__panel .info__panel-title {
  font-weight: 600;
  font-size: 25px;
  color: #333333;
  margin-bottom: 1rem;
}
#property-details .info__panel .info__panel-content {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  line-height: 160%;
}
#property-details .stars {
  -webkit-transform: none;
          transform: none;
  margin-left: 0.5rem;
}
#property-details .stars span {
  width: 16px;
  margin-right: 4px;
}
#property-details .search {
  margin-bottom: 0;
}
#property-details .search #search-form {
  background: transparent;
  padding: 0;
}
#property-details .search #search-form #search-form-autocomplete,
#property-details .search #search-form #filters {
  display: none !important;
}
#property-details .slick-slide {
  cursor: pointer;
}
@media (max-width: 991.98px) {
#property-details .property__rates {
    margin-top: 0;
}
#property-details .stars {
    margin-left: 0;
}
#property-details .stars span {
    width: 14px;
}
}
div[data-class=internalGallery] .modal-dialog {
  max-width: calc(100% - 16px);
}
div[data-class=internalGallery] .closeInternal {
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  padding: 1rem;
  font-size: 24px;
  cursor: pointer;
  z-index: 99999;
}
div[data-class=internalGallery] .modal.show {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
div[data-class=internalGallery] .modal-dialog {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
div[data-class=internalGallery] .modal-content {
  background-color: transparent;
}
div[data-class=internalGallery] .modal-content,
div[data-class=internalGallery] .slick-container,
div[data-class=internalGallery] .slick-slider,
div[data-class=internalGallery] .slick-list,
div[data-class=internalGallery] .slick-track,
div[data-class=internalGallery] .slick-slide,
div[data-class=internalGallery] .slick-slide div {
  height: 100%;
}
div[data-class=internalGallery] .slick-slide {
  position: relative;
}
div[data-class=internalGallery] .internalImage {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
div[data-class=internalGallery] .internalImage img {
  width: auto;
  margin: 0 auto;
  max-width: 100%;
}
div[data-class=internalGallery] .slick-caption {
  height: auto !important;
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
@media (min-width: 768px) {
div[data-class=internalGallery] .slick-caption {
    font-size: 18px;
}
}
div[data-class=internalGallery] .slick-dots {
  bottom: 0;
}
.modal-content .property__amenities {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.modal-content .info__panel-title {
  font-weight: 600;
  font-size: 25px;
  color: #333333;
  margin-bottom: 1rem;
}
.modal-content .info__panel-content {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  line-height: 160%;
}
.property-details-modal {
  text-align: left;
}
.property-details-modal p {
  font-size: 15px;
  font-weight: 300;
  color: #333333;
}
.property-details-modal:first-child .property-detail-title {
  margin-top: 0;
}
.property-details-modal .property-detail-title {
  margin-top: 1rem;
  color: #333333;
}
.property-details-modal ul {
  margin-bottom: 0.75rem;
}
.property-details-modal li {
  padding-left: 1rem;
  margin-left: 1rem;
  position: relative;
  display: block;
  width: 100%;
}
.property-details-modal li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #333333;
}
@media (max-width: 575.98px) {
.rooms > .panel {
    padding: 15px;
    text-align: center;
}
}
.rooms .search .panel-gray {
  display: none;
}
.rooms > .panel {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
.rooms .search .panel-gray {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rooms .search .panel-gray #search-form-buttons {
    -webkit-box-flex: 13% !important;
        -ms-flex: 13% !important;
            flex: 13% !important;
    max-width: 13% !important;
}
.rooms .search .panel-gray #search-form-occupancy,
.rooms .search .panel-gray #search-form-nights,
.rooms .search .panel-gray #search-form-datepicker {
    -webkit-box-flex: 29% !important;
        -ms-flex: 29% !important;
            flex: 29% !important;
    max-width: 29% !important;
}
.rooms > .panel {
    margin-bottom: 0;
}
}
.rooms #search-form {
  width: 100%;
  min-width: 100%;
}
.rooms #search-form #search-form-buttons {
  padding-right: 0;
}
@media (min-width: 992px) {
.rooms #search-form form #search-form-datepicker,
.rooms #search-form form #search-form-occupancy,
.rooms #search-form form #search-form-buttons,
.rooms #search-form form #search-form-nights {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    max-width: 25%;
}
}
@media (min-width: 992px) {
#app.property #appHeader,
#app.map-detail #appHeader {
    display: none;
}
}