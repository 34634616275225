.btn-chevron-right {
  color: #0077CA;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn-chevron-right svg {
  margin-left: 12px;
}