#property-reviews .slick-list {
  padding-left: 0.75rem;
  margin-left: -0.75rem;
  margin-top: 2rem;
}
#property-reviews .slick-list .slick-slide {
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#property-reviews .slick-list .slick-slide:not(.slick-active) {
  opacity: 0;
}
#property-reviews .property-reviews__title {
  font-weight: 600;
  font-size: 23px;
  color: #333333;
  margin: 0;
}
#property-reviews .reviews__dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 1rem;
}
#property-reviews .reviews__dots span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 38px;
  height: 38px;
  background: #fff;
  color: #666666;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}
#property-reviews .review * {
  font-family: "Century Gothic", CenturyGothic, Arial, sans-serif !important;
  color: #333333;
  cursor: pointer;
}
#property-reviews .review {
  background: #fff;
  border-radius: 6px;
  position: relative;
  margin-top: 30px;
}
#property-reviews .review .review__icons {
  display: none;
}
@media (min-width: 768px) {
#property-reviews .review .review__icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transform: translateX(-12px) translateY(-16px);
            transform: translateX(-12px) translateY(-16px);
}
}
#property-reviews .review .review__content {
  padding: 30px;
  padding-top: 0;
  position: relative;
  height: 260px;
  overflow: hidden;
}
#property-reviews .review .review__content:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 80px;
  left: 0;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(transparent));
  background: linear-gradient(0deg, #fff, transparent 100%);
  border-bottom: 20px solid #fff;
  border-radius: 6px;
}
#property-reviews .review .small {
  line-height: 120%;
  font-size: 12px;
  margin-bottom: 12px;
  margin-top: 6px;
}
#property-reviews .review .review__avatar {
  width: 44px;
  height: 44px;
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 1;
}
#property-reviews .review .review__rating {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  background: #FF9F00;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
}
#property-reviews .review .review__stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#property-reviews .review .review__title {
  margin-top: 0;
}
#property-reviews .review .review__text {
  margin-bottom: 0;
}
#property-reviews[data-size=compact] .reviewsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#property-reviews[data-size=compact] .reviewsTitle,
#property-reviews[data-size=compact] .reviewsSubitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: none;
}
#property-reviews[data-size=compact] .slick-wrapper {
  min-width: 100%;
  padding: 0 30px;
  position: relative;
}
#property-reviews[data-size=compact] .slick-wrapper .slick-list {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}
#property-reviews[data-size=compact] .slick-wrapper #compact-slick-prev-review {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0;
  padding: 0;
  width: 16px;
}
#property-reviews[data-size=compact] .slick-wrapper #compact-slick-next-review {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0;
  padding: 0;
  width: 16px;
}
#property-reviews[data-size=compact] .review {
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
#property-reviews[data-size=compact] .small {
  margin: 0.5rem 0;
}
#property-reviews[data-size=compact] .review__icons {
  -webkit-transform: none;
          transform: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 100px;
  max-width: 100px;
}
#property-reviews[data-size=compact] .review__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 140px;
  padding: 0 0 0 0.5rem;
}
#property-reviews[data-size=compact] .review__title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
#property-reviews[data-size=compact] .review__text {
  font-size: 12px;
  line-height: 120%;
}
#property-reviews[data-size=compact] .reviews__dots {
  margin-top: 0;
}
#property-reviews[data-size=large] {
  margin: 4rem 0;
}
@media (min-width: 576px) {
#property-reviews[data-size=large] .review,
#property-reviews[data-size=large] .reviews__dots {
    margin-right: 60px;
}
}