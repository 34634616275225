.property__rates {
  margin: 16px auto;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-left: 22px;
  /*
  .property__scorestars
  {
      position:relative;
      text-align:center;
      margin-left:1rem;
      display:inline-block;
      .stars
      {
          transform:translateY(-2px);
      }
  }
  */
}
.property__rates > svg {
  position: absolute;
  left: -6px;
  top: 3px;
}
.property__rates .property__scoreicon {
  width: 70px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  color: #fff;
  text-align: center;
}
.property__rates .property__scoreicon svg {
  width: 100%;
}
.property__rates .property__scoreicon .score {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}